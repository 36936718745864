/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.text-link {
  @apply text-blue-600 hover:underline hover:text-blue-800;
}

.divider {
  @apply w-full h-px border-none;
  background:
  linear-gradient(
      90deg,
      rgba(230,226,217,0),
      rgba(230,226,217,1) 15%,
      rgba(230,226,217,1) 75%,
      rgba(230,226,217,0)
  );
}

@layer base {
  html {
    font-family: 'Amiri', serif;
  }
}

.prose {
  @apply prose-p:leading-loose;
  @apply prose-a:font-normal prose-a:text-blue-700 prose-a:decoration-1 prose-a:underline-offset-2 prose-a:decoration-blue-500 ;
  @apply hover:prose-a:text-blue-900 hover:prose-a:decoration-blue-800;
}


.aa-DetachedSearchButton {
  flex-direction: row-reverse!important;
}

.aa-DetachedSearchButton {
  @apply !bg-a-tan-mid !bg-opacity-40 !rounded;
}

.aa-DetachedSearchButton .aa-DetachedSearchButtonIcon {
  @apply !text-white !-scale-x-1;
}
.aa-DetachedSearchButtonPlaceholder {
  @apply !text-white;
}

.prose .paragraph-number {
  @apply absolute w-10 p-1 -mr-12 text-xs leading-5 text-left text-black no-underline rounded-sm hover:bg-gray-100 sm:hover:bg-gray-200;
}
